/* You can add global styles to this file, and also import other style files */
.mobilenumber input[type=number]::-webkit-inner-spin-button, 
.mobilenumber input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label {
    font-size: 15px;
    font-weight: bold;
    color: black;
    text-transform: capitalize;
}
.leftimage {height: 40px;width: auto;}
.rightimage {    margin-right: 30px;width: auto;float: right;}
.card-header{
  padding: 1rem 1.5rem 0rem 1.5rem!important;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
// @import "~@ng-select/ng-select/themes/material.theme.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

